import Vue from 'vue';
import VueRouter from 'vue-router';

import Layout from './layouts/Layout.vue';
import Game from './pages/Game.vue';
import Results from './pages/Results.vue';
import PageNotFound from './pages/PageNotFound.vue';

export default function(params)
{
	params["router"] = new VueRouter({
		mode: 'history',
		base: '/',
		routes:
		[
			{
				path: '/',
				redirect: '/game',
			},
			{
				path: '/',
				component: Layout,
				children: [
					{
						path: '/game',
						component: Game,
					},
					{
						path: '/results',
						component: Results,
					},
					{
						path: '*',
						component: PageNotFound,
					},
				],
				props: { default: true, namespace: ["page", "Home"] }
			},
		],
		//linkActiveClass: 'active',
		//linkExactActiveClass: 'active',
	});
	
	params["store"].registerModule(["page", "Layout"], Layout.buildStore());
	params["store"].registerModule(["page", "Game"], Game.buildStore());
	
	return params;
}
