<template>
	<router-view />
</template>

<style lang="css">

</style>

<script>
export default {
	name: 'Layout',
	props: ['namespace'],
	data: () => ({
		
	}),
	buildStore: (state) => {
		if(state == null || state == undefined){
			state = {
				
			};
		}
		
		var res = {
			namespaced: true,
			state: () => {
				return state;
			},
			modules: {},
			mutations: {},
		}
		
		return res;
	},
	computed: {
		model(){
			return this.getModel();
		},
		layout(){
			return this.$store.state.page.Layout
		},
	},
	methods: {
		
	},
	created(){
		
	},
}
</script>
