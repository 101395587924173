<template>
	<div>
		<audio><source src="//mapgame2.bsrv.su/static/audio/empty_drop.mp3"></source></audio>
		<audio><source src="//mapgame2.bsrv.su/static/audio/booster_drop.mp3"></source></audio>
		<audio><source src="//mapgame2.bsrv.su/static/audio/booster_dropped.mp3"></source></audio>
		<audio><source src="//mapgame2.bsrv.su/static/audio/bandits_drop.mp3"></source></audio>
		<audio><source src="//mapgame2.bsrv.su/static/audio/bandits_drop2.mp3"></source></audio>
		<audio><source src="//mapgame2.bsrv.su/static/audio/bandits_hit.mp3"></source></audio>
		<audio><source src="//mapgame2.bsrv.su/static/audio/bandits_cleared.mp3"></source></audio>
		<audio><source src="//mapgame2.bsrv.su/static/audio/nextlevel_drop.mp3"></source></audio>
		<audio><source src="//mapgame2.bsrv.su/static/audio/nextlevel_dropped.mp3"></source></audio>
		<audio><source src="//mapgame2.bsrv.su/static/audio/gameover.mp3"></source></audio>
		<audio><source src="//mapgame2.bsrv.su/static/audio/gamestart.mp3"></source></audio>
		<div class="wrapper">
			<div class="game-loading" v-if="overlay">
				<div class="lds-css" style="width: 250px; height: 250px; margin: 0 auto;">
					<div class="lds-spinner white" style="width: 100%; height: 100%;">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div>
			</div>
			<div class="game-cont" v-if="!overlay">
				<div class="container-left">
					<div class="panel panel-info">
						<div class="panel-heading text-center">
							<b>{{character.username.toUpperCase()||'ИГРОК'}}</b><br/>
							<img src="//mapgame2.bsrv.su/static/img/player.png" />
						</div>
						<div class="list-group">
							<div class="list-group-item" title="Единицы жизни">HP: {{character.hp}}</div>
							<div class="list-group-item" title="Мин-макс атака (единицы силы атаки)">DMG: {{character.damage_min}}-{{character.damage_max}}</div>
							<div class="list-group-item" title="Шанс уклонения от удара">EV: {{character.evasion}}%</div>
							<div class="list-group-item" title="Уровень">LVL: {{character.level}}</div>
							<div class="list-group-item" title="Очки">EXP: {{character.exp}}</div>
						</div>
					</div>
					<div class="panel panel-default">
						<div class="list-group">
							<div class="list-group-item list-group-item-warning">При переходе на новый уровень сила атаки бандитов увеличится на {{bandits_damage_nextlevel_plus}} единиц силы, их здоровье пополнится на {{bandits_health_nextlevel_plus}} единиц жизни и шанс уклонения от атаки увеличится на {{bandits_evasion_nextlevel_plus}}%.</div>
							<div class="list-group-item list-group-item-warning">При неполной зачистке уровня от бандитов, они станут сильнее на {{bandits_damage_nextlevel_plus2}} единиц силы, их здоровье пополнится дополнительно на {{bandits_health_nextlevel_plus2}} единиц жизни и шанс уклонения от атаки увеличится на {{bandits_evasion_nextlevel_plus2}}%.</div>
							<div class="list-group-item list-group-item-warning">При незачистке уровня от бандитов, они станут сильнее на {{bandits_damage_nextlevel_plus3}} единиц силы, их здоровье пополнится дополнительно на {{bandits_health_nextlevel_plus3}} единиц жизни и шанс уклонения от атаки увеличится на {{bandits_evasion_nextlevel_plus3}}%.</div>
						</div>
					</div>
				</div>
				<div class="container-right">
					<div class="panel panel-danger">
						<div class="panel-heading text-center">
							<b>БАНДИТЫ</b><br/>
							<img src="//mapgame2.bsrv.su/static/img/bandits.png" />
						</div>
						<div class="list-group">
							<div class="list-group-item" title="Единицы жизни">HP: {{bandits.hp}}</div>
							<div class="list-group-item" title="Мин-макс атака (единицы силы атаки)">DMG: {{bandits.damage_min}}-{{bandits.damage_max}}</div>
							<div class="list-group-item" title="Шанс уклонения от удара">EV: {{bandits.evasion}}%</div>
						</div>
					</div>
					<div class="panel panel-default">
						<div class="list-group">
							<div class="list-group-item list-group-item-warning">Бонус к здоровью восполняет {{booster_health_plus}} единиц(у) жизни</div>
							<div class="list-group-item list-group-item-warning">Бонус к силе атаки добавляет {{booster_damage_plus}} единиц(у) силы</div>
							<div class="list-group-item list-group-item-warning">Бонус к шансу уклонения от атаки добавляет {{booster_evasion_plus}} процент шанса</div>
							<div class="list-group-item list-group-item-warning">Переход на новый уровень добавляет {{booster_exp_nextlevel_plus}} очков</div>
							<div class="list-group-item list-group-item-warning">Открытие не открытой ячейки добавляет {{booster_exp_open_plus}} очков</div>
							<div class="list-group-item list-group-item-warning">Открытие ячейки бонуса к здоровью добавляет {{booster_exp_health_plus}} очков</div>
							<div class="list-group-item list-group-item-warning">Открытие ячейки бонуса к шансу уклонения от атаки добавляет {{booster_exp_evasion_plus}} очков</div>
							<div class="list-group-item list-group-item-warning">Открытие ячейки бонуса к атаке добавляет {{booster_exp_damage_plus}} очков</div>
							<div class="list-group-item list-group-item-warning">Зачистка ячейки от бандитов добавляет {{getExp()}} очков</div>
						</div>
					</div>
				</div>
				<div class="container-game unselectable">
					<div class="block">
						<div class="subblock">
							<div class="row" v-for="i in [0, 1, 2, 3, 4]" v-if="map.length > 0">
								<div :class="[
									'elem',
									{[map[i][j].type]: map[i][j].type != '' && map[i][j].opened},
									{[map[i][j].subtype]: map[i][j].type != '' && map[i][j].opened},
									{'fog-available': map[i][j].blocked <= 0 && map[i][j].available && !map[i][j].opened},
									{'fog': map[i][j].blocked <= 0 && !map[i][j].available},
									{'fog-blocked': map[i][j].blocked > 0}
								]" @click="clickElem({x: +i, y: +j})" v-for="j in [0, 1, 2, 3, 4]">
									<div class="hp" v-if="map[i][j].type == 'bandits' && map[i][j].opened && !map[i][j].clear">HP: {{map[i][j].hp}}</div>
									<map-elem-text
										v-for="n in map[i][j].notys"
										v-bind:color="n.color"
										v-bind:text="n.text"
									></map-elem-text>
								</div>
							</div>
						</div>
					</div>
					<div class="footer">
						<div class="door"></div>
					</div>
					
					<div class="game-log">
						<div class="panel panel-default text-center" style="min-height: 430px;">
							<div class="panel-heading">ЛОГ ИГРЫ</div>
							<div class="list-group">
								<div class="list-group-item" v-for="logtext in log">{{logtext}}</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	</div>
</template>

<style lang="css">
.game-log {
	margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 635px;
}
</style>

<script>
import MapElemText from '../components/MapElemText.vue';
import Swal from '../assets/js/sweetalert2@10.js';

export default {
	name: 'Game',
	props: ['namespace'],
	data: () => ({
		overlay: true,
		
		log: [
			'Игра началась',
		],
		
		soundOn: true,		// включить звуки
		
		booster_health_count: 2,	// количество генерируемых бонусов увеличения здоровья персонажа на карте
		booster_damage_count: 2,	// количество генерируемых бонусов увеличения атаки персонажа на карте
		bandits_count: 6,			// количество генерируемых бандитов на карте
		
		booster_health_plus: 80,		// на сколько единиц увеличивается здоровье при получении бонуса
		booster_damage_plus: 4,			// на сколько единиц увеличивается атака при получении бонуса
		booster_evasion_plus: 2,		// на сколько процентов увеличивается шанс уклонения от атаки при получении бонуса
		booster_exp_nextlevel_plus: 20,	// на сколько единиц увеличивается опыт при переходе на новый уровень
		booster_exp_open_plus: 5,		// на сколько единиц увеличивается опыт при открытии ячейки
		booster_exp_health_plus: 5,		// на сколько единиц увеличивается опыт при получении бонуса к здоровью в ячейке
		booster_exp_damage_plus: 5,		// на сколько единиц увеличивается опыт при получении бонуса к силе атаки в ячейке
		booster_exp_evasion_plus: 5,	// на сколько единиц увеличивается опыт при получении бонуса к шансу уклонения от атаки
		
		character_hp_def: 200,			// единицы жизни персонажа по умолчанию
		character_damage_min_def: 20,	// минимальный урон персонажа по умолчанию
		character_damage_max_def: 30,	// максимальный урон персонажа по умолчанию
		character_evasion_def: 5,		// процент вероятности уклонения от удара по умолчанию
		character_level_def: 1,			// количество прошедших уровней по умолчанию
		character_exp_def: 0,			// общий полученный опыт по умолчанию
		
		bandits_hp_def: 60,				// единицы жизни бандитов по умолчанию
		bandits_damage_min_def: 15,		// минимальный урон бандитов по умолчанию
		bandits_damage_max_def: 20,		// максимальный урон бандитов по умолчанию
		bandits_evasion_def: 1,			//процент вероятности уклонения от удара по умолчанию
		
		bandits_health_nextlevel_plus: 10,		// на сколько единиц увеличивается здоровье бандитов при переходе на новый уровень
		bandits_damage_nextlevel_plus: 3,		// на сколько единиц увеличивается атака бандитов при переходе на новый уровень
		bandits_evasion_nextlevel_plus: 1,		// на сколько единиц увеличивается шанс уклонения от атаки бандитов при переходе на новый уровень
		bandits_health_nextlevel_plus2: 10,	// на сколько единиц дополнительно увеличивается здоровье бандитов при переходе на новый уровень, если на текущем уровне не все бандиты зачищены
		bandits_damage_nextlevel_plus2: 1,		// на сколько единиц дополнительно увеличивается атака бандитов при переходе на новый уровень, если на текущем уровне не все бандиты зачищены
		bandits_evasion_nextlevel_plus2: 2,	// на сколько единиц дополнительно увеличивается шанс уклонения от атаки бандитов при переходе на новый уровень, если на текущем уровне не все бандиты зачищены
		bandits_health_nextlevel_plus3: 10,	// на сколько единиц дополнительно увеличивается здоровье бандитов при переходе на новый уровень, если на текущем уровне бандиты вообще не зачищены
		bandits_damage_nextlevel_plus3: 3,		// на сколько единиц дополнительно увеличивается атака бандитов при переходе на новый уровень, если на текущем уровне бандиты вообще не зачищены
		bandits_evasion_nextlevel_plus3: 1,	// на сколько единиц дополнительно увеличивается шанс уклонения от атаки бандитов при переходе на новый уровень, если на текущем уровне бандиты вообще не зачищены
		
		// персонаж
		character: {
			username: 'Игрок',	// имя или ник персонажа
			hp: 200,			// единицы жизни персонажа
			damage_min: 20,		// минимальный урон персонажа
			damage_max: 30,		// максимальный урон персонажа
			evasion: 5,			// процент вероятности уклонения от удара
			level: 1,			// количество прошедших уровней
			exp: 0,				// общий полученный опыт
		},
		
		// бандиты
		bandits: {
			hp: 60,				// единицы жизни бандитов
			damage_min: 15,		// минимальный урон бандитов
			damage_max: 20,		// максимальный урон бандитов
			evasion: 1,			// процент вероятности уклонения от удара
		},
		
		// дроп с бандитов
		bandits_booster: [
			{
				type: '',
				dropChance: 0.6,
			},
			{
				type: 'health',
				dropChance: 0.4,
			},
			{
				type: 'damage',
				dropChance: 0.3,
			},
			{
				type: 'evasion',
				dropChance: 0.2,
			},
			{
				type: 'bandits',
				dropChance: 0.2,
			}
		],
		
		map: [],	// карта
	}),
	methods: {
		// добавление в лог
		addGameLog(text = ''){
			if(text == '') return;
			
			this.log.unshift(text);
			
			if(this.log.length > 10){
				this.log.splice(10, this.log.length - 10);
			}
		},
		// получение дропа с бандитов
		getDrop(){
			const lerp = (min, max, value) => ((1 - value) * min + value * max);
			const total = this.bandits_booster.reduce((accumulator, item) => (accumulator += item.dropChance), 0);
			const chance = lerp(0, total, Math.random());
			let current = 0;
			for(const item of this.bandits_booster){
				if(current <= chance && chance < current + item.dropChance){
					return item;
				}
				current += item.dropChance;
			}
		},
		// проигрывание звука
		play(action){
			if(this.soundOn){
				let audio = null;
				switch(action){
					case 'empty_drop':
						audio = document.getElementsByTagName('audio')[0];
						break;
					case 'booster_drop':
						audio = document.getElementsByTagName('audio')[1];
						break;
					case 'booster_dropped':
						audio = document.getElementsByTagName('audio')[2];
						break;
					case 'bandits_drop':
						audio = document.getElementsByTagName('audio')[3];
						break;
					case 'bandits_drop2':
						audio = document.getElementsByTagName('audio')[4];
						break;
					case 'bandits_hit':
						audio = document.getElementsByTagName('audio')[5];
						break;
					case 'bandits_cleared':
						audio = document.getElementsByTagName('audio')[6];
						break;
					case 'nextlevel_drop':
						audio = document.getElementsByTagName('audio')[7];
						break;
					case 'nextlevel_dropped':
						audio = document.getElementsByTagName('audio')[8];
						break;
					case 'gameover':
						audio = document.getElementsByTagName('audio')[9];
						break;
					case 'gamestart':
						audio = document.getElementsByTagName('audio')[10];
						break;
				}
				if(audio != null){
					audio.load();
					audio.play();
				}
			}
		},
		// расчет получаемого опыта за побежденных бандитов
		getExp(){
			return 50 + (50 * this.character.level||1);
		},
		// генерация данных карты игры
		genMapData(){
			this.map = [];
			
			let ar = [];
			let ar_booster_health = [];
			let ar_booster_damage = [];
			let ar_bandits = [];
			let ar_nextlevel = [];
			
			let rnd = 0;
			// переход на следующий уровень
			do {
				rnd = this.mt_rand(0, 5 * 5);
			} while(ar.indexOf(rnd) !== -1 || rnd == 22)
			ar_nextlevel.push(rnd);
			ar.push(rnd);
			// бандиты
			for(let k1 = 0; k1 < this.bandits_count; k1++){
				do {
					rnd = this.mt_rand(0, 5 * 5);
				} while(ar.indexOf(rnd) !== -1 || rnd == 22)
				ar_bandits.push(rnd);
				ar.push(rnd);
			}
			// бустер здоровья
			for(let k1 = 0; k1 < this.booster_health_count; k1++){
				do {
					rnd = this.mt_rand(0, 5 * 5);
				} while(ar.indexOf(rnd) !== -1 || rnd == 22)
				ar_booster_health.push(rnd);
				ar.push(rnd);
			}
			// бустер силы атаки
			for(let k1 = 0; k1 < this.booster_damage_count; k1++){
				do {
					rnd = this.mt_rand(0, 5 * 5);
				} while(ar.indexOf(rnd) !== -1 || rnd == 22)
				ar_booster_damage.push(rnd);
				ar.push(rnd);
			}
			
			let ar_pos = 0;
			for(let i = 0; i < 5; i++){
				for(let j = 0; j < 5; j++){
					let el_type = '';
					let el_subtype = '';
					if(ar_nextlevel.indexOf(ar_pos) !== -1){
						el_type = 'nextlevel';
					} else if(ar_bandits.indexOf(ar_pos) !== -1){
						el_type = 'bandits';
						let subtypes = ['', 'goblins1', 'goblins2', 'troll', 'minotaur', 'nezhit'];
						el_subtype = subtypes[this.mt_rand(0, subtypes.length - 1)];
					} else if(ar_booster_health.indexOf(ar_pos) !== -1){
						el_type = 'health';
					} else if(ar_booster_damage.indexOf(ar_pos) !== -1){
						el_type = 'damage';
					}
					if(typeof(this.map[i]) == 'undefined') this.map[i] = [];
					this.map[i][j] = {
						type: el_type, // тип ячейки
						subtype: el_subtype, // подтип ячейки
						blocked: 0, // заблокирована ли ячейка (незачищенными бандитами)
						available: (i == 3 && j == 2) || (i == 4 && (j == 1 || j == 2 || j == 3)), // доступна ли ячейка для открытия
						opened: i == 4 && j == 2, // открыта ли ячейка
						hp: el_type == 'bandits' ? this.bandits.hp : 0, // оставшиеся жизни бандитов в ячейке
						evasion: el_type == 'evasion' ? this.bandits.hp : 0, // оставшиеся жизни бандитов в ячейке
						clear: el_type == '', // зачищена ли ячейка (от бандитов и бонусов)
						
						notys: [],
					};
					ar_pos++;
				}
			}
		},
		// клик по элементу карты
		clickElem(pos){
			let map_elem = this.map[pos.x][pos.y];
			if(map_elem.blocked <= 0
			&& map_elem.available){
				if(!map_elem.opened){
					this.addGameLog('Открыта ячейка ['+pos.x+' | '+pos.y+']');
					map_elem.opened = true;
					if(pos.x < 4) this.map[pos.x + 1][pos.y].available = true;
					if(pos.x > 0) this.map[pos.x - 1][pos.y].available = true;
					if(pos.y < 4) this.map[pos.x][pos.y + 1].available = true;
					if(pos.y > 0) this.map[pos.x][pos.y - 1].available = true;
					if(map_elem.type == 'bandits'
					&& !map_elem.clear){
						this.play('bandits_drop');
						if(pos.x > 0) if(!this.map[pos.x - 1][pos.y].opened) this.map[pos.x - 1][pos.y].blocked++;
						if(pos.x < 4) if(!this.map[pos.x + 1][pos.y].opened) this.map[pos.x + 1][pos.y].blocked++;
						if(pos.y < 4) if(!this.map[pos.x][pos.y + 1].opened) this.map[pos.x][pos.y + 1].blocked++;
						if(pos.y > 0) if(!this.map[pos.x][pos.y - 1].opened) this.map[pos.x][pos.y - 1].blocked++;
						this.addGameLog('Обнаружены бандиты');
					} else if(map_elem.type == 'health'){
						this.play('booster_drop');
						this.addGameLog('Обнаружен бонус к здоровью');
					} else if(map_elem.type == 'damage'){
						this.play('booster_drop');
						this.addGameLog('Обнаружен бонус атаки');
					} else if(map_elem.type == 'evasion'){
						this.play('booster_drop');
						this.addGameLog('Обнаружен бонус шанса уклонения от атаки');
					} else if(map_elem.type == 'nextlevel'){
						this.play('nextlevel_drop');
						this.addGameLog('Обнаружен проход на следующий уровень');
					} else {
						this.play('empty_drop');
					}
					this.addGameLog('За открытие ячейки получено '+this.booster_exp_open_plus+' очков');
					this.character.exp += this.booster_exp_open_plus; // за открытие ячейки
				} else {
					if(!map_elem.clear){
						if(map_elem.type == 'bandits'){
							// деремся с бандитами
							this.addGameLog('Деремся с бандитами в ячейке ['+pos.x+' | '+pos.y+']');
							
							var bandits_evased = Math.random() < this.bandits.evasion / 100;
							if(!bandits_evased){
								let character_attack = this.mt_rand(this.character.damage_min, this.character.damage_max);
								this.addGameLog('У бандитов отнято '+character_attack+' очков жизни');
								map_elem.hp -= character_attack;
								this.addMapElemText({
									x: pos.x,
									y: pos.y,
									color: '#f00',
									text: '-'+character_attack,
								});
							} else {
								this.addGameLog('Бандиты успешно уклонились от удара');
								this.addMapElemText({
									x: pos.x,
									y: pos.y,
									color: '#0f0',
									text: '0',
								});
							}
							
							var character_evased = Math.random() < this.character.evasion / 100;
							if(!character_evased){
								let bandits_attack = this.mt_rand(this.bandits.damage_min, this.bandits.damage_max);
								this.addGameLog('Бандитами отнято '+bandits_attack+' очков жизни');
								this.character.hp -= bandits_attack;
							} else {
								this.addGameLog('Вы успешно уклонились от удара');
							}
							
							if(map_elem.hp < 0) map_elem.hp = 0;
							if(this.character.hp < 0) this.character.hp = 0;
							
							if(map_elem.hp <= 0){
								let drop = this.getDrop();
								if(drop.type != 'bandits'){
									this.addGameLog('Ячейка ['+pos.x+' | '+pos.y+'] зачищена от бандитов');
								}
								if(drop.type != ''){
									if(drop.type == 'bandits'){
										if(this.character.hp > 0) this.play('bandits_drop2');
										this.addGameLog('Побежденные бандиты успели вызвать подкрепление!');
										map_elem.hp = this.bandits.hp;
										
										let added_exp = this.getExp(); // за зачистку группы бандитов ячейки
										this.addGameLog('За зачистку группы бандитов ячейки получено '+added_exp+' очков');
										this.character.exp += added_exp;
									} else if(drop.type == 'health'){
										if(this.character.hp > 0) this.play('booster_drop');
										this.addGameLog('Обнаружен бонус к здоровью');
										map_elem.type = 'health';
									} else if(drop.type == 'damage'){
										if(this.character.hp > 0) this.play('booster_drop');
										this.addGameLog('Обнаружен бонус атаки');
										map_elem.type = 'damage';
									} else if(drop.type == 'evasion'){
										if(this.character.hp > 0) this.play('booster_drop');
										this.addGameLog('Обнаружен бонус шанса уклонения от атаки');
										map_elem.type = 'evasion';
									}
								} else {
									if(this.character.hp > 0) this.play('bandits_cleared');
									map_elem.clear = true;
									map_elem.type = '';
									
									let added_exp = this.getExp(); // за зачистку ячейки от бандитов
									this.addGameLog('За зачистку ячейки получено '+added_exp+' очков');
									this.character.exp += added_exp;
								}
								if(drop.type != 'bandits'){
									// +|
									if(pos.x > 0) if(!this.map[pos.x - 1][pos.y].opened) this.map[pos.x - 1][pos.y].blocked--;
									// |+
									if(pos.x < 4) if(!this.map[pos.x + 1][pos.y].opened) this.map[pos.x + 1][pos.y].blocked--;
									// +
									// -
									if(pos.y > 0) if(!this.map[pos.x][pos.y - 1].opened) this.map[pos.x][pos.y - 1].blocked--;
									// -
									// +
									if(pos.y < 4) if(!this.map[pos.x][pos.y + 1].opened) this.map[pos.x][pos.y + 1].blocked--;
								}
							} else {
								this.play('bandits_hit');
							}
							if(this.character.hp <= 0){
								this.addGameLog('Игра окончена');
								this.gameover();
							}
						} else if(map_elem.type == 'health'){
							// получили бонус к здоровью
							this.play('booster_dropped');
							this.addGameLog('Восполнены единицы жизни на '+this.booster_health_plus);
							this.character.hp += this.booster_health_plus;
							
							this.addMapElemText({
								x: pos.x,
								y: pos.y,
								color: '#0f0',
								text: '+'+this.booster_health_plus,
							});
							
							this.addGameLog('За получение бонуса к здоровью получено '+this.booster_exp_health_plus+' очков');
							this.character.exp += this.booster_exp_health_plus; // за получение бонуса
							
							map_elem.type = '';
							map_elem.clear = true;
						} else if(map_elem.type == 'damage'){
							// получили бонус к силе атаки
							this.play('booster_dropped');
							this.addGameLog('Восполнены единицы к силе атаки на '+this.booster_damage_plus);
							this.character.damage_min += this.booster_damage_plus;
							this.character.damage_max += this.booster_damage_plus;
							
							this.addMapElemText({
								x: pos.x,
								y: pos.y,
								color: '#00f',
								text: '+'+this.booster_damage_plus,
							});
							
							this.addGameLog('За получение бонуса к силе атаки получено '+this.booster_exp_damage_plus+' очков');
							this.character.exp += this.booster_exp_damage_plus; // за получение бонуса
							
							map_elem.type = '';
							map_elem.clear = true;
						} else if(map_elem.type == 'evasion'){
							// получили бонус к уклонению от удара
							this.play('booster_dropped');
							this.addGameLog('Увеличен шанс уклонения от атаки на '+this.booster_evasion_plus+'%');
							this.character.evasion += this.booster_evasion_plus;
							
							this.addMapElemText({
								x: pos.x,
								y: pos.y,
								color: '#0ff',
								text: '+'+this.booster_evasion_plus+'%',
							});
							
							this.addGameLog('За получение бонуса увеличения шанса уклонения от атаки получено '+this.booster_exp_evasion_plus+' очков');
							this.character.exp += this.booster_exp_evasion_plus; // за получение бонуса
							
							map_elem.type = '';
							map_elem.clear = true;
						} else if(map_elem.type == 'nextlevel'){
							// перешли на следующий уровень
							this.play('nextlevel_dropped');
							this.character.level++;
							this.addGameLog('Переход на '+this.character.level+' уровень');
							
							this.addGameLog('За переход на следующий уровень получено '+this.booster_exp_nextlevel_plus+' очков');
							this.character.exp += this.booster_exp_nextlevel_plus; // за переход на следующий уровень
							
							this.addGameLog('Бандиты пополнили свое здоровье на '+this.bandits_health_nextlevel_plus+' единиц жизни');
							this.bandits.hp += this.bandits_health_nextlevel_plus;
							
							this.addGameLog('Бандиты увеличили силу атаки на '+this.bandits_damage_nextlevel_plus+' единиц силы');
							this.bandits.damage_min += this.bandits_damage_nextlevel_plus;
							this.bandits.damage_max += this.bandits_damage_nextlevel_plus;
							
							this.addGameLog('Бандиты увеличили шанс уклонения от атаки на '+this.bandits_evasion_nextlevel_plus+'%');
							this.bandits.evasion += this.bandits_evasion_nextlevel_plus;
							
							let flag_bandits_exists = false, bandits_cnt = 0;
							for(let i = 0; i < 5; i++){
								for(let j = 0; j < 5; j++){
									if(this.map[i][j].type == 'bandits'){
										if(!this.map[i][j].clear) flag_bandits_exists = true;
										bandits_cnt++;
									}
								}
							}
							if(bandits_cnt == this.bandits_count){
								this.addGameLog('За незачистку уровня от бандитов, бандиты пополнили свое здоровье на '+this.bandits_health_nextlevel_plus3+' единиц жизни');
								this.bandits.hp += this.bandits_health_nextlevel_plus3;
								
								this.addGameLog('За незачистку уровня от бандитов, бандиты увеличили силу атаки на '+this.bandits_damage_nextlevel_plus3+' единиц силы');
								this.bandits.damage_min += this.bandits_damage_nextlevel_plus3;
								this.bandits.damage_max += this.bandits_damage_nextlevel_plus3;
								
								this.addGameLog('За незачистку уровня от бандитов, бандиты увеличили шанс уклонения от атаки на '+this.bandits_evasion_nextlevel_plus3+'%');
								this.bandits.evasion += this.bandits_evasion_nextlevel_plus3;
							} else if(flag_bandits_exists){
								this.addGameLog('За неполную зачистку уровня от бандитов, бандиты пополнили свое здоровье на '+this.bandits_health_nextlevel_plus2+' единиц жизни');
								this.bandits.hp += this.bandits_health_nextlevel_plus2;
								
								this.addGameLog('За неполную зачистку уровня от бандитов, бандиты увеличили силу атаки на '+this.bandits_damage_nextlevel_plus2+' единиц силы');
								this.bandits.damage_min += this.bandits_damage_nextlevel_plus2;
								this.bandits.damage_max += this.bandits_damage_nextlevel_plus2;
								
								this.addGameLog('За неполную зачистку уровня от бандитов, бандиты увеличили шанс уклонения от атаки на '+this.bandits_evasion_nextlevel_plus2+'%');
								this.bandits.evasion += this.bandits_evasion_nextlevel_plus2;
							}
							this.genMapData();
						}
					}
				}
			}
		},
		// запуск игры
		start(){
			this.overlay = true;
			
			let username = this.getUrlVars()['u']||'';
			
			if(username != ''){
				Swal.fire({
					title: 'Начать игру под ником<br/>'+username+'?',
					//text: "You won't be able to revert this!",
					icon: 'question',
					showCancelButton: true,
					showDenyButton: true,
					confirmButtonText: 'Начать игру',
					confirmButtonColor: '#5CB85C',
					denyButtonText: 'Сменить ник',
					denyButtonColor: '#757575',
					cancelButtonText: 'Результаты',
					cancelButtonColor: '#2778c4',
					allowOutsideClick: false,
					allowEscapeKey: false,
				}).then((result) => {
					if(result.isConfirmed){
						this.play('gamestart');
						Swal.fire({
							title: 'Игра началась!',
							//text: "You won't be able to revert this!",
							icon: 'success',
							showConfirmButton: false,
							allowOutsideClick: false,
							allowEscapeKey: false,
							timer: 1000,
						});
						
						this.character.username = username;
						this.character.hp = this.character_hp_def;
						this.character.damage_min = this.character_damage_min_def;
						this.character.damage_max = this.character_damage_max_def;
						this.character.evasion = this.character_evasion_def;
						this.character.level = this.character_level_def;
						this.character.exp = this.character_exp_def;
						
						this.bandits.hp = this.bandits_hp_def;
						this.bandits.damage_min = this.bandits_damage_min_def;
						this.bandits.damage_max = this.bandits_damage_max_def;
						this.bandits.evasion = this.bandits_evasion_def;
						
						this.log = [
							'Игра началась',
						];
						
						this.genMapData();
						
						this.overlay = false;
					} else if(result.dismiss === Swal.DismissReason.cancel){
						this.$router.push({path: '/results', query: {u: username}});
					} else {
						this.$router.push({path: '/game'});
					}
				});
			} else {
				Swal.fire({
					title: 'Игра "Зачистка подземелья"',
					input: 'text',
					inputLabel: 'Имя или ник игрока',
					inputPlaceholder: 'Введите Ваше имя или ник',
					inputValidator: (value) => {
						if(!value){
							return 'Для начала игры укажите Ваше имя или ник';
						}
					},
					width: 550,
					customClass: {
						input: 'text-center',
					},
					showCancelButton: true,
					confirmButtonText: 'Начать игру',
					confirmButtonColor: '#5CB85C',
					cancelButtonText: 'Результаты',
					cancelButtonColor: '#2778c4',
					allowOutsideClick: false,
					allowEscapeKey: false,
				}).then((result) => {
					if(result.isConfirmed){
						this.$router.push({path: '/game', query: {u: result.value}});
					} else {
						this.$router.push({path: '/results'});
					}
				});
			}
		},
		// конец игры
		gameover(){
			this.play('gameover');
			Swal.fire({
				//title: 'Сохранение результатов...',
				html: (
					'<div class="lds-css" style="width: 250px; height: 250px; margin: 0 auto;">'+
						'<div class="lds-spinner black" style="width: 100%; height: 100%;">'+
							'<div></div>'+
							'<div></div>'+
							'<div></div>'+
							'<div></div>'+
							'<div></div>'+
							'<div></div>'+
							'<div></div>'+
							'<div></div>'+
							'<div></div>'+
							'<div></div>'+
							'<div></div>'+
							'<div></div>'+
						'</div>'+
					'</div>'+
					'<h3>Сохранение результатов...</h3>'
				),
				showConfirmButton: false,
				allowOutsideClick: false,
				allowEscapeKey: false,
			});
			this.sendQuery({
				type: 'post',
				params: {
					action: 'set-results',
					username: this.character.username,
					level: this.character.level,
					exp: this.character.exp,
				},
				success: (data) => {
					if(data != null && data.error_code == '1'){
						let results_level = data.level || [];
						let results_exp = data.exp || [];
						
						let res1_html = '', cnt1 = 0;
						for(let el of results_level){
							res1_html += (
								'<div class="list-group-item'+(cnt1 < 3 ? '  list-group-item-warning' : '')+'">'+
									'<div style="float: left; width: 60px;">'+(el.level||'?')+'</div>'+
									'<div style="float: right; width: 30px; height: 30px; margin: -5px -10px 0 5px;">'+
										(cnt1 < 3 ?
											'<img src="/static/img/medal'+(cnt1 + 1)+'.png" style="width: 30px; height: 30px;" />'
										:'')+
									'</div>'+
									'<div>'+(el.username||'Игрок')+'</div>'+
								'</div>'
							);
							cnt1++;
						};
						let res2_html = '', cnt2 = 0;
						for(let el of results_exp){
							res2_html += (
								'<div class="list-group-item'+(cnt2 < 3 ? '  list-group-item-warning' : '')+'">'+
									'<div style="float: left; width: 70px;">'+(el.exp||'?')+'</div>'+
									'<div style="float: right; width: 30px; height: 30px; margin: -5px -10px 0 5px;">'+
										(cnt2 < 3 ?
											'<img src="/static/img/medal'+(cnt2 + 1)+'.png" style="width: 30px; height: 30px;" />'
										:'')+
									'</div>'+
									'<div>'+(el.username||'Игрок')+'</div>'+
								'</div>'
							);
							cnt2++;
						};
						
						Swal.fire({
							title: 'Ваши результаты:',
							html: (
								'<div class="list-group">'+
									'<div class="list-group-item bg-success">Вы достигли '+this.character.level+' уровня</div>'+
									'<div class="list-group-item bg-success">Вы набрали '+this.character.exp+' очков</div>'+
								'</div>'+
								'<div style="width: 430px; float: left;">'+
									'<div class="list-group" style="margin-bottom: 0;">'+
										'<div class="list-group-item bg-primary">'+
											'<strong>ТОП-10 ЛИДЕРОВ ПО УРОВНЯМ</strong>'+
										'</div>'+
										res1_html+
									'</div>'+
								'</div>'+
								'<div style="width: 430px; float: right;">'+
									'<div class="list-group" style="margin-bottom: 0;">'+
										'<div class="list-group-item bg-primary">'+
											'<strong>ТОП-10 ЛИДЕРОВ ПО ОЧКАМ</strong>'+
										'</div>'+
										res2_html+
									'</div>'+
								'</div>'
							),
							width: 1000,
							showConfirmButton: true,
							allowOutsideClick: false,
							allowEscapeKey: false,
						}).then((result) => {
							//location.reload();
							this.start();
						});
					} else {
						console.log('Что-то пошло не так...');
						this.$router.push({path: '/results', query: {u: this.character.username}});
					}
				},
				error: (error) => {
					console.log(error);
				},
			});
		},
		// показ всплывающего текста на карте игры
		addMapElemText(obj){
			this.map[obj.x][obj.y].notys.push({
				color: obj.color,
				text: obj.text,
			});
		},
		
		microtime(get_as_float){
			// discuss at: http://phpjs.org/functions/microtime/
			// original by: Paulo Freitas
			// example 1: timeStamp = microtime(true);
			// example 1: timeStamp > 1000000000 && timeStamp < 2000000000
			// returns 1: true
			var now = new Date().getTime() / 1000;
			var s = parseInt(now, 10);
			return (get_as_float) ? now : (Math.round((now - s) * 1000) / 1000) + ' ' + s;
		},
		mt_rand(min, max){
			return Math.floor(Math.random() * (max - min) + min);
		},
		ioGenPkid(){
			return Math.floor(this.microtime(1) * 10000)+''+this.mt_rand(10, 99);
		},
		getUrlVars(){
			var vars = {};
			var parts = decodeURI(location.href).replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value){
				vars[key] = value;
			});
			return vars;
		},
		sendQuery(arr){
			var arr = arr || {};
			var type = arr.type || 'get';
			var params = arr.params || {};
			var asuccess = arr.success || {};
			var aerror = arr.error || {};
			
			axios({
				url: 'https://mapgame2.bsrv.su/api/'+params.action,
				data: params,
				method: type.toUpperCase(),
			}).then(resp => asuccess(resp.data)).catch(err => aerror(err));
		},
	},
	beforeMount(){
		this.start();
		//this.loadPostData(this.$route.params.id);
	},
	 beforeRouteUpdate(to, from, next){
		next();
		this.start();
		//this.loadPostData(to.params.id);
	},
	buildStore: (state) => {
		if(state == null || state == undefined){
			state = {};
		}
		var res = {
			namespaced: true,
			state: () => {return state;},
			modules: {},
			mutations: {},
		}
		return res;
	},
	computed: {
		model(){
			return this.getModel();
		},
		layout(){
			return this.$store.state.page.Layout
		},
	},
	components: {
		MapElemText,
	},
}
</script>