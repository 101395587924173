<template>
	<div>
		
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import Swal from '../assets/js/sweetalert2@10.js';

export default {
	name: 'Results',
	props: ['namespace'],
	data: () => ({
		
	}),
	methods: {
		getResults(){
			this.sendQuery({
				type: 'get',
				params: {
					action: 'get-results',
				},
				success: (data) => {
					if(data != null && data.error_code == '1'){
						let results_level = data.level || [];
						let results_exp = data.exp || [];
						
						let res1_html = '', cnt1 = 0;
						for(let el of results_level){
							res1_html += (
								'<div class="list-group-item'+(cnt1 < 3 ? '  list-group-item-warning' : '')+'">'+
									'<div style="float: left; width: 60px;">'+(el.level||'?')+'</div>'+
									'<div style="float: right; width: 30px; height: 30px; margin: -5px -10px 0 5px;">'+
										(cnt1 < 3 ?
											'<img src="/static/img/medal'+(cnt1 + 1)+'.png" style="width: 30px; height: 30px;" />'
										:'')+
									'</div>'+
									'<div>'+(el.username||'Игрок')+'</div>'+
								'</div>'
							);
							cnt1++;
						};
						let res2_html = '', cnt2 = 0;
						for(let el of results_exp){
							res2_html += (
								'<div class="list-group-item'+(cnt2 < 3 ? '  list-group-item-warning' : '')+'">'+
									'<div style="float: left; width: 70px;">'+(el.exp||'?')+'</div>'+
									'<div style="float: right; width: 30px; height: 30px; margin: -5px -10px 0 5px;">'+
										(cnt2 < 3 ?
											'<img src="/static/img/medal'+(cnt2 + 1)+'.png" style="width: 30px; height: 30px;" />'
										:'')+
									'</div>'+
									'<div>'+(el.username||'Игрок')+'</div>'+
								'</div>'
							);
							cnt2++;
						};
						
						Swal.fire({
							title: 'Ваши результаты:',
							html: (
								'<div style="width: 430px; float: left;">'+
									'<div class="list-group" style="margin-bottom: 0;">'+
										'<div class="list-group-item bg-primary">'+
											'<strong>ТОП-10 ЛИДЕРОВ ПО УРОВНЯМ</strong>'+
										'</div>'+
										res1_html+
									'</div>'+
								'</div>'+
								'<div style="width: 430px; float: right;">'+
									'<div class="list-group" style="margin-bottom: 0;">'+
										'<div class="list-group-item bg-primary">'+
											'<strong>ТОП-10 ЛИДЕРОВ ПО ОЧКАМ</strong>'+
										'</div>'+
										res2_html+
									'</div>'+
								'</div>'
							),
							width: 1000,
							showConfirmButton: true,
							confirmButtonText: 'Назад в игру',
							allowOutsideClick: false,
							allowEscapeKey: false,
						}).then((result) => {
							let username = this.getUrlVars()['u']||'';
							this.$router.push({path: '/game', query: {u: username}});
						});
					} else {
						console.log('Что-то пошло не так...');
						let username = this.getUrlVars()['u']||'';
						this.$router.push({path: '/results', query: {u: username}});
					}
				},
				error: (error) => {
					console.log(error);
				},
			});
		},
		
		microtime(get_as_float){
			// discuss at: http://phpjs.org/functions/microtime/
			// original by: Paulo Freitas
			// example 1: timeStamp = microtime(true);
			// example 1: timeStamp > 1000000000 && timeStamp < 2000000000
			// returns 1: true
			var now = new Date().getTime() / 1000;
			var s = parseInt(now, 10);
			return (get_as_float) ? now : (Math.round((now - s) * 1000) / 1000) + ' ' + s;
		},
		mt_rand(min, max){
			return Math.floor(Math.random() * (max - min) + min);
		},
		ioGenPkid(){
			return Math.floor(this.microtime(1) * 10000)+''+this.mt_rand(10, 99);
		},
		getUrlVars(){
			var vars = {};
			var parts = decodeURI(location.href).replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value){
				vars[key] = value;
			});
			return vars;
		},
		sendQuery(arr){
			var arr = arr || {};
			var type = arr.type || 'get';
			var params = arr.params || {};
			var asuccess = arr.success || {};
			var aerror = arr.error || {};
			
			axios({
				url: 'https://mapgame2.bsrv.su/api/'+params.action,
				data: params,
				method: type.toUpperCase(),
			}).then(resp => asuccess(resp.data)).catch(err => aerror(err));
		},
	},
	beforeMount(){
		this.getResults();
	},
	 beforeRouteUpdate(to, from, next){
		next();
		this.getResults();
	},
	buildStore: (state) => {
		if(state == null || state == undefined){
			state = {};
		}
		var res = {
			namespaced: true,
			state: () => { return state; },
			modules: {},
			mutations: {},
		}
		return res;
	},
	computed: {
		model(){
			return this.getModel();
		},
		layout(){
			return this.$store.state.page.Layout
		},
	},
}
</script>