<template>
	<div class="text-center animate__animated animate__fadeOutUp" :style="{position: 'absolute', zIndex: 2, bottom: '25px', left: 0, width: '113px', color: (color||'#4DBC43'), textShadow: '0 0 20px '+(color||'#4DBC43'), fontSize: '35px', fontWeight: 'bold'}">{{text||''}}</div>
</template>

<style lang="css" scoped>

</style>

<script>
export default {
	name: 'MapElemText',
	props: ['namespace', 'text', 'color'],
	data: () => ({
		
    }),
	buildStore: (state) => {
		if(state == null || state == undefined){
			state = {};
		}
		var res = {
			namespaced: true,
			state: () => {
				return state;
			},
			modules: {},
			mutations: {},
		}
		return res;
	},
	beforeMount(){
		
	},
	computed: {},
	methods: {},
}
</script>